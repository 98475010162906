import React from 'react'
import styles from './UltimasPreguntas.module.scss'
import { handleChange } from '../../../utils/constants'
import { useContext } from 'react';
import { ProfileContext } from './NewProfile';

const UltimasPreguntas = () => {
  const { valuesProfile, setValuesProfile } = useContext(ProfileContext);
  const { familia_publico, nombre_publico, parentesco_publico, persona_pep, recursos_publicos } = valuesProfile.pepData
  return (
    <section className={styles.container}>
      <div className={styles.pregunta}>
        <p>¿Eres una persona expuesta públicamente? (PEP)</p>
        <div className={styles.respuestas}>
          <label htmlFor='persona_pep'>
            <input 
              type="radio" 
              name="persona_pep" 
              value="Si"
              checked={persona_pep === "Si"}
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'pepData')}
              id='persona_pep'
            />
              Sí
          </label>
          <label>
            <input 
              type="radio" 
              name="persona_pep" 
              value="No"
              checked={persona_pep === "No"}
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'pepData')}
              id='persona_pep'
            />
              No
          </label>
        </div>
      </div>
      <div className={styles.pregunta}>
        <p>¿Manejas recursos públicos o recursos provenientes de la contratación de entidades públicas?</p>
        <div className={styles.respuestas}>
          <label>
            <input 
              type="radio" 
              name="recursos_publicos" 
              value="Si"
              checked={recursos_publicos === 'Si'}
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'pepData')}
              id='recursos_publicos'
            />
              Sí
          </label>
          <label>
            <input 
              type="radio" 
              name="recursos_publicos"
              checked={recursos_publicos === 'No'}
              value="No" onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'pepData')}
              id='recursos_publicos'
            />
              No
          </label>
        </div>
      </div>
      <div className={styles.pregunta}>
        <p>¿Tienes familiares que desempeñen o hayan desempeñado un cargo público?</p>
        <div className={styles.respuestas}>
          <label>
            <input 
              type="radio" 
              name="familia_publico" 
              value="Si"
              checked={familia_publico === 'Si'}
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'pepData')}
              id='familia_publico'
            />
              Sí
          </label>
          <label>
            <input 
              type="radio" 
              name="familia_publico" 
              value="No"
              checked={familia_publico === 'No'}
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'pepData')}
              id='familia_publico'
            />
              No
          </label>
        </div>
      </div>
      {
        familia_publico === "Si" && (
          <div className={styles.names}>
            <input 
              type="text" 
              className={styles.custom_input} 
              placeholder='Nombre' 
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'pepData')} 
              name='nombre_publico' 
              value={nombre_publico}/>
            <input 
              type="text" 
              className={styles.custom_input} 
              placeholder='Parentesco' 
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'pepData')} 
              name='parentesco_publico' 
              value={parentesco_publico}/>
          </div>
        )
      }
    </section>
  )
}

export default UltimasPreguntas