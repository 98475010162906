import React from "react";
import { Alert } from "antd";
import Topbar from "../common/topbar/Topbar";
import Footer from "../common/footer/index";
import './404.scss';

export const NotFound = () => (
<>
    <header className="sticky">
        <Topbar />
    </header>
    <div class="notfound">
        <Alert data-cy="welcome" message="Oops! url no encontrada." description="" type="error" />
    </div>
    <Footer />
</>
);

export default NotFound;
