import React from 'react'
import styles from './QuienSoy.module.scss'
import Person from '../../../graphics/icon_person.webp'
import { estadosCiviles, handleChange } from '../../../utils/constants'
import { useContext } from 'react'
import { ProfileContext } from './NewProfile'
import { Icon, Select, message } from 'antd'
import { Continentes } from './forms/lists/Countries'
import moment from 'moment'

const { Option, OptGroup } = Select;

const QuienSoy = () => {
  const { valuesProfile, setValuesProfile, props } = useContext(ProfileContext);
  const { first_name, last_name, last_name2, numero_documento, fecha_expedicion, fecha_nacimiento, ciudad_nacimiento, direccion, ciudad, pais, estado_civil } = valuesProfile.personalData;
  const _user = props.auth.user ? props.auth.user.User : null;
  let _formatDateExpedicion = fecha_expedicion;
  if(!fecha_expedicion &&  _user?.fecha_expedicion_doc) {
    _formatDateExpedicion = moment(_user.fecha_expedicion_doc).format('yyyy-MM-DD');
  }
  const referralCode =
      _user && _user.first_name && _user.id
        ? _user.first_name.toLowerCase().replace(/\s/g, "") + _user.id
        : "";
  const copyCode = (referralCode) => {
    navigator.clipboard.writeText(referralCode);
    message.success("Código copiado!");
  }
  return (
    <section className={styles.container}>
      <div className={styles.left_content}>
        <img src={Person} alt="imagen inversionista" className={styles.imagen_inversionista}/>
        <h3 className={styles.codigo_title}>Tú código para referir: <span onClick={() => copyCode(referralCode)}><Icon type='copy' /></span></h3>
        <p>{referralCode}</p>
        <p>¡Cuéntale a tus amigos de <span>la vaca</span>inmobiliaria!</p>
      </div>
      <div className={styles.right_content}>
        <p className={styles.question_title}>Información personal</p>
        <ul className={styles.personal_information}>
          <li className={styles.personal_information_item}>
            <label htmlFor='first_name'>Nombres</label>
            <input 
              type="text" 
              className={styles.custom_input}
              name='first_name'
              value={first_name}
              id='first_name'
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'personalData')}
            />
          </li>
          <li className={styles.personal_information_item}>
            <label htmlFor='last_name'>Apellido paterno</label>
            <input 
              type="text" 
              className={styles.custom_input} 
              name='last_name'
              value={last_name}
              id='last_name'
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'personalData')}
            />
          </li>
          <li className={styles.personal_information_item}>
            <label htmlFor='last_name'>Apellido materno</label>
            <input 
              type="text" 
              className={styles.custom_input} 
              name='last_name2'
              value={last_name2}
              id='last_name2'
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'personalData')}
            />
          </li>
          <li className={styles.personal_information_item}>
            <label htmlFor='numero_documento'>Identificación</label>
            <input 
              type="text" 
              className={styles.custom_input} 
              name='numero_documento'
              value={numero_documento}
              id='numero_documento'
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'personalData')}
            />
          </li>
          <li className={styles.personal_information_item}>
            <label htmlFor='fechaExpedicion'>Fecha Expedición</label>
            <input 
              type="date" 
              className={styles.custom_input} 
              name='fecha_expedicion'
              value={_formatDateExpedicion}
              id='fecha_expedicion'
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'personalData')}
            />
          </li>
          <li className={styles.personal_information_item}>
            <label htmlFor='fechaNacimiento'>Fecha Nacimiento</label>
            <input 
              type="date" 
              className={styles.custom_input}
              name='fecha_nacimiento'
              value={fecha_nacimiento}
              id='fecha_nacimiento'
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'personalData')}
            />
          </li>
          <li className={styles.personal_information_item}>
            <label htmlFor='lugarNacimiento'>Lugar Nacimiento</label>
            <input 
              type="text" 
              className={styles.custom_input} 
              name='ciudad_nacimiento'
              value={ciudad_nacimiento}
              id='ciudad_nacimiento'
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'personalData')}
            />
          </li>
        </ul>
        <p className={styles.question_title}>¿Resides en Colombia o en el exterior?</p>
        <ul className={styles.residencia}>
          <li className={styles.residencia_item}>
            <p>En Colombia</p>
            <input 
              type="radio" 
              name="pais" 
              value="Colombia" 
              className={styles.input_margin}
              id='colombia'
              checked={pais === "Colombia"}
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'personalData')}
            />
          </li>
          <li className={styles.residencia_item}>
            <p>En el exterior</p>
            <input 
              type="radio" 
              name="pais" 
              value="Exterior" 
              className={styles.input_margin}
              id='exterior'
              checked={pais === "Exterior"}
              onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'personalData')}
            />
          </li>
        </ul>
        <ul className={styles.direccion}>
          <li className={styles.direccion_item}>
            <label htmlFor='direccion'>Dirección</label>
            <input 
            type="text" 
            className={styles.custom_input} 
            name='direccion'
            value={direccion}
            id='direccion'
            onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'personalData')}
            />
          </li>
          <li className={styles.direccion_item}>
            <label htmlFor='ciudad'>Ciudad</label>
            <input 
            type="text" 
            className={styles.custom_input} 
            name='ciudad'
            value={ciudad}
            id='ciudad'
            onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'personalData')}
            />
          </li>
          {
            pais === 'Exterior' && (
              <li className={styles.direccion_item}>
                <label htmlFor='pais'>País</label>
                <Select name="pais" id="pais" defaultValue={pais}>
                  {
                    Continentes.map(({ id, label, continente }) => (
                      <OptGroup label={label} key={id}>
                        {continente.map(({ name }) => (
                          <Option
                            key={name} 
                            value={name} 
                            onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'personalData')}>
                              {name}
                          </Option>
                        ))}
                      </OptGroup>
                    ))
                  }
                </Select>
              </li>
            )
          }
        </ul>
        <p className={styles.question_title}>¿Cuál es tu estado civil?</p>
        <ul className={styles.estado_civil}>
          {
            estadosCiviles.map(({ key, name, value, id, label }) => (
              <li className={styles.estado_civil_item} key={key}>
                <p>{label}</p>
                <input 
                  type="radio" 
                  name={name} 
                  value={value}
                  checked={value === estado_civil}
                  className={styles.input_margin}
                  id={id}
                  onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'personalData')}
                  />
              </li>
            ))
          }
        </ul>
      </div>
    </section>
  )
}

export default QuienSoy