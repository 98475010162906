import { independientes } from "./constants";
import isExpressInvestment from "./expressInvestment";

const checkPendingDocs = (_user) => {

    var pending_docs = [];
    var pending_profile = [];
    var pending_fiducia_docs = [];

    //var all_documents_uploaded = false;
    const required_docs_natural = [
        { doc: "documento_identidad_status", text: "* Documento de identidad" },
        { doc: "documento_rut_status", text: "* RUT Persona Natural (Actualizado)" },
        { doc: "documento_ingresos_1_status", text: "* Declaración de renta 2022 o certificado de ingresos y retenciones 2022" },
        { doc: "documento_certificacion_bancaria_status", text: "* Certificación de cuenta bancaria con vigencia no mayor a 60 dias" },
    ];

    const required_docs_juridica = [
        { doc: "documento_identidad_status", text: "* Cédula de representante legal" },
        { doc: "documento_rut_status", text: "* RUT (Actualizado)" },
        { doc: "documento_ingresos_1_status", text: "* Declaración de renta 2022 o certificado de ingresos y retenciones 2022" },
        { doc: "documento_certificacion_bancaria_status", text: "* Certificación de cuenta bancaria con vigencia no mayor a 60 dias" },
        { doc: "documento_composicion_accionaria_status", text: "* Certificación de composición accionaria" },
        { doc: "documento_estados_financieros_status", text: "* Estados financieros a ultimo corte" },
        { doc: "documento_camara_comercio_status", text: "* Certificado de Cámara de comercio no mayor a 60 días" }
    ];

    if (_user) {

        //Persona Jurídica
        if (_user.type_person === "juridica") {

            //Check documents
            required_docs_juridica.forEach(required_doc => {
                if (_user[required_doc.doc] === "faltante") {
                    pending_docs.push(required_doc.text)
                }
            })

            //Check declaraciones
            if (_user.declaracion_origen !== true) {
                pending_docs.push("* Aceptar la declaración de origen de fondos")
            }

            //Check user profile
            if (_user.profesional_full !== true) {
                pending_profile.push("* Completar información de la empresa")
            }
            if (_user.financiero_full !== true) {
                pending_profile.push("* Completar datos financieros")
            }
            if (_user.pep_full !== true) {
                pending_profile.push("* Completar información PEP (Persona públicamente expuesta)")
            }
            if (_user.personal_full !== true) {
                pending_profile.push("* Completar información del representante legal")
            }
            if(isExpressInvestment(_user) === true) {
                return { docs: [], profile: pending_profile, missing_docs: [] };
            }
            return { docs: pending_docs, profile: pending_profile, missing_docs: pending_docs };
        }


        //Persona natural

        //Check documents
        required_docs_natural.forEach(required_doc => {
            if (_user[required_doc.doc] === "faltante") {
                pending_docs.push(required_doc.text)
            }
        })

        //Check declaraciones
        if (_user.declaracion_origen !== true) {
            pending_docs.push("* Aceptar la declaración de origen de fondos")
        }

        //Check user profile
        if (_user.personal_full !== true) {
            pending_profile.push("* Completar información personal")
        }
        if (_user.profesional_full !== true) {
            pending_profile.push("* Completar información profesional")
        }
        if (_user.financiero_full !== true) {
            pending_profile.push("* Completar información financiera")
        }
        if (_user.pep_full !== true) {
            pending_profile.push("* Completar información PEP (Persona públicamente expuesta)")
        }

        //Check fiducia documentation
        if (_user.ocupacion === "pensionado" && _user.documento_resolucion_pension_status === "faltante") {
            pending_fiducia_docs.push("* Documento resolución de pensión")
        }

        if (independientes.includes(_user.ocupacion)) {
            if (_user.documento_certificado_contador_independiente_status === "faltante") {
                pending_fiducia_docs.push("* Certificado expedido por un contador público con fotocopia de tarjeta profesional")
            }
            if(_user.ocupacion === "hogar") {
                if(_user.documento_extracto_bancario_independiente_status === "faltante") {
                    pending_docs.push("* Extracto bancario de los últimos 3 meses");
                }
            }
        }

        if (_user.documento_ingresos_1_status === "faltante" && (_user.ocupacion === "estudiante" || _user.ocupacion === "hogar")) {
            pending_fiducia_docs.push("* Certificación de no declarante de renta")
        }

        if (_user.ocupacion === "estudiante" && _user.documento_ingresos_2_status === "faltante") {
            pending_docs.push("* Carta de la persona que es responsable económica del estudiante")
        }

        if (_user.ocupacion === "empleado" && _user.documento_certificacion_laboral_status === "faltante") {
            pending_fiducia_docs.push("* Certificación laboral")
        }

        if(_user.documento_ingresos_1_status !== "aprobado"){
            pending_fiducia_docs.push("* Declaración de renta actualizada año 2022")
        }
        if(isExpressInvestment(_user) === true) {
            return { docs: [], profile: pending_profile, pending_fiducia: [], missing_docs: pending_docs };
        }
    }
    return { docs: pending_docs, profile: pending_profile, pending_fiducia: pending_fiducia_docs, missing_docs: pending_docs };
}




export default checkPendingDocs;
