import ReactGA from 'react-ga';
function trackEvent(event) {
    /*
    const dataLayer = window.dataLayer || []
    dataLayer.push(event)
    */
    ReactGA.event(event);
}

export default trackEvent;
