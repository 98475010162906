import { findPath } from "@utils/tree";

let _store;

export default function select({ getState }) {
  return next => action => {
    const returnValue = next(action);
    const nextState = getState();
    _store = nextState;
    const actionType = String(action.type);
    switch (actionType) {      
      case "SELECT":
        getNodeSelected();
        break;      
    }
    return returnValue;
  };
}


const getNodeSelected = () => {

  const _selected = _store.selection.selected;
  const _path = findPath(_selected, _store.structure);
  if (_path) {
  }
};