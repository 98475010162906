import React from 'react';
import { BRAND_UP } from '@utils/constants';

export const tratamientoDatos =
<>
<h2>{BRAND_UP} Autorización para el Tratamiento de datos personales</h2>
<br/>
<p>Manifiesto que la presente autorización me fue solicitada y puesta de presente
antes de entregar mis datos personales y que la suscribo de forma libre y
voluntaria una vez leída en su totalidad, por lo cual, otorgo mi consentimiento a {BRAND_UP}, con número de identificación tributaria NIT. 901.403.018 – 0; domiciliada en
la ciudad de Bogotá, Colombia; para que trate mi información personal de acuerdo
con la Política de Privacidad de la Información dispuesta por la empresa en la
página web y que me fue presentada antes de recolectar mis datos personales.
</p><br/>

<p>
{ BRAND_UP} será el responsable del tratamiento y, en tal virtud, podrá recopilar,
almacenar, consultar, usar, procesar, circular, actualizar, transferir y/o transmitir a
terceros, suprimir y, en general realizar cualquier tipo de tratamiento para las
siguientes finalidades:
</p><br/>

<p>
a. Comunicación con los Titulares para efectos de mercadeo de productos y
servicios, atención de solicitudes, quejas y/o reclamos; confirmar su
asistencia a eventos, confirmación de información, presentación de
propuestas, confirmación de datos y facturación de servicios.
</p><br/>

<p>
b. Adelantar procesos de evaluación sobre la calidad de nuestros productos y
servicios
</p><br/>

<p>
Usar los datos suministrados por ellos de manera electrónica, a través de Sitios Web, de aplicaciones para teléfonos inteligentes (Smartphone) en ferias y eventos entre otros, para enviarles a los correos electrónicos registrados.
Utilizar la información recibida de ellos, para fines de mercadeo de productos y servicios.
Compartir los datos personales con nuestros representantes comerciales, según sea requerido para el manejo comercial de nuestros productos y servicios.
Permitir el acceso a la información y datos personales a los auditores o terceros contratados para llevar a cabo procesos de auditoría interna o externa propios de la actividad comercial que desarrollamos.
Consultar y actualizar los datos personales, en cualquier tiempo, con el fin de mantener actualizada dicha información.
Contratar con terceros el almacenamiento y/o procesamiento de la información y datos personales para la correcta ejecución de los contratos celebrados con nosotros, bajo los estándares de seguridad y confidencialidad a los cuales estamos obligados.
</p><br/>

<p>
c. Proveer productos y servicios, Información comercial, publicitaria o
promocional sobre los productos y/o servicios, eventos y/o promociones de
tipo comercial o no de estas, con el fin de impulsar, invitar, dirigir, ejecutar,
informar y de manera general, llevar a cabo campañas, promociones o
concursos de carácter comercial o publicitario.
</p><br/>

<p>
d. Promover el desarrollo de nuestro objeto social, y el cumplimiento de las
prestaciones contractuales pactadas con el Titular.
</p><br/>

<p>
e. Investigación de mercados, así como su análisis para establecer tendencias
o perfiles de consumo de los Titulares.
</p><br/>

<p>
f. Analizar, monitorear y estudiar la información de la Base de Datos para
mantener, aumentar y mejorar los servicios ofrecidos por {BRAND_UP}.
</p><br/>

<p>
SUMINISTRO DE LOS DATOS PERSONALES DE LOS DESTINATARIOS A LOS CLIENTES DE { BRAND_UP }. En caso de que alguno de nuestros Destinatarios responda a la publicidad mediante el registro de sus datos personales en el formulario dispuesto para ello, el Destinatario autoriza expresa e inequívocamente a { BRAND_UP } para que se contacte al Destinatario interesado en la publicidad por medio de correo electrónico, postal o teléfono, así como para que dichos datos se incorporen y almacenen en la base de datos de { BRAND_UP } para los efectos señalados en el numeral 2 del presente documento.
</p><br/>

<p>
g. Realización de procesos de auditoría interna o externa propios de la
actividad comercial que desarrollamos.
</p><br/>

<p>
h. Cumplir con las prestaciones contractuales y/o legales que {BRAND_UP} suscribe con terceros.
</p><br/>

<p>
i. Reportar a los clientes los hábitos de apertura, reenvío, supresión de sus
piezas publicitarias.
</p><br/>

<p>
MEDIDAS DE SEGURIDAD PARA LA PROTECCIÓN DE LOS DATOS PERSONALES Y OTRA INFORMACIÓN. Las medidas de seguridad con las que cuenta { BRAND_UP } buscan proteger los datos de los Destinatarios en aras de impedir su adulteración, pérdida, usos y accesos no autorizados. Para ello { BRAND_UP } de forma diligente implementa medidas de protección humanas, administrativas y técnicas que razonablemente están a su alcance, dentro de las cuales se encuentran las obligaciones contractuales de privacidad y confidencialidad en cabeza de sus empleados, contratistas y proveedores. Con todo, el Destinatario reconoce que no existe un método que sea totalmente seguro, por lo cual no se puede garantizar la seguridad absoluta. En estos términos el Destinatario acepta esta forma de protección, declarando que la considera suficiente para todos los propósitos.
</p><br/>

<p>
En este sentido, nos apoyamos en herramientas tecnológicas de seguridad e implementamos prácticas de seguridad reconocidas en la industria, que incluyen: transmisión y almacenamiento de información sensible a través de mecanismos seguros, tales como cifrado, uso de protocolos seguros; aseguramiento de componentes tecnológicos, restricción de acceso a la información sólo a personal autorizado, respaldo de información, prácticas de desarrollo seguro de software, entre otros.
</p><br/>

<p>
j. Garantizar la seguridad de los datos personales, su accesibilidad, integridad
y confidencialidad durante su tratamiento.
</p><br/>

<p>
k. Suministrar a las autoridades competentes la información que sea
requerida en cumplimiento de las leyes, regulaciones o procesos judiciales o
administrativos.
</p><br/>

<p>
l. Delegar a terceros, en calidad de Encargados, los tratamientos descritos,
dentro de Colombia o fuera de ella.
</p><br/>

<p>
Son derechos de los titulares de la información:
</p><br/>

<p>
a. Conocer, actualizar y rectificar sus datos personales frente a los
responsables del Tratamiento o Encargados del Tratamiento. Este derecho
se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos,
fraccionados, que induzcan a error, o aquellos cuyo Tratamiento esté
expresamente prohibido o no haya sido autorizado;
</p><br/>

<p>
b. Solicitar prueba de la autorización otorgada al responsable del Tratamiento
salvo cuando expresamente se exceptúe como requisito para el
Tratamiento;
</p><br/>

<p>
c. Presentar ante la Superintendencia de Industria y Comercio quejas por
infracciones a lo dispuesto en la ley y las demás normas que la modifiquen,
adicionen o complementen;
</p><br/>

<p>
d. Revocar la autorización y/o solicitar la supresión del dato personal, excepto
cuando exista un deber legal o contractual que impida eliminarlos.
</p><br/>

<p>
e. Acceder en forma gratuita a sus datos personales que hayan sido objeto de
Tratamiento.
</p><br/>

<h2>
ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS.
</h2><br/>

<p>
Estos derechos podrán ser ejercidos a través de los canales dispuestos para ello
por parte de Weseed para la atención al público:
</p><br/>

<ul>
    <li>Correo electrónico: carolina@soyweseed.com</li>
    <li>Dirección: Cra. 11B #99-25 Wework piso 16, Bogotá D.C.</li>
    <li>Cel: +57 3196251522</li>
</ul>

<p>
El horario de atención será de 8:00 a.m. a 6:00 p.m. para la atención de
requerimientos relacionados con el tratamiento de mis datos personales y el
ejercicio de los derechos mencionados.
</p><br/>

</>;

export default tratamientoDatos;
