export const getInfoExtractedDocumentoIdentidad = (e, valuesProfile, setValuesProfile, state, setState) => {
  const  { personalData }  = valuesProfile
  if(e.extracted_info){
      const { nombres: first_name, apellidos, numero_de_documento, fecha_expedicion, fecha_nacimiento, lugar_nacimiento } = e.extracted_info
      const numero_documento = numero_de_documento?.replaceAll(".","");
      const lugar_nacimiento_split = lugar_nacimiento?.split(" ")
      const apellidos_split = apellidos?.split('')
      //FALTA ENVIAR FECHAS PORQUE EL FORMATO ES DIFERENTE - REVISAR
      const infoExtracted = {
        ...valuesProfile,
        personalData: {
          ...personalData,
          first_name,
          last_name: apellidos_split[0] || '',
          last_name2: apellidos_split[1] || '',
          fecha_expedicion, 
          fecha_nacimiento,
          numero_documento,
          ciudad_nacimiento: lugar_nacimiento_split || ''
        }
      }
      setValuesProfile(infoExtracted)
      setState({ ...state,  identificacionDoc: true, loading: false})
    }
  }
  
  export const getInfoExtractedDeclaracionRenta = (e, valuesProfile, setValuesProfile, state, setState) => {
    const { financialData } = valuesProfile;
    if(e.extracted_info){
      const { deudas: egresos, ingresos_brutos: ingresos, patrimonio_bruto: activos } = e.extracted_info
      const infoExtracted = {
        ...valuesProfile,
        financialData: {
          ...financialData,
          ingresos, 
          egresos, 
          activos
        }
      }
      setValuesProfile(infoExtracted)
      setState({ ...state,  ingresosDoc: true, loading: false})
    }
  }