import { Button, Icon, Upload, message } from 'antd'
import React, { useState } from 'react'
import styles from './Dedico.module.scss'
import { handleChange, nivelEstudios, ocupaciones } from '../../../utils/constants'
import { useContext } from 'react'
import { ProfileContext } from './NewProfile'

const Dedico = () => {
  const { valuesProfile, setValuesProfile, props, setResolucionPensionDoc, resolucionPensionDoc } = useContext(ProfileContext);
  const userId = props.auth.user.User.id;
  const { ocupacion, profesion, nivel_educacion, empleador, empresa } = valuesProfile.professionalData
  let nivelEducativo = nivel_educacion
  if(nivelEducativo !== ""){
    nivelEducativo = nivel_educacion?.charAt(0).toUpperCase() + nivel_educacion?.slice(1)
  }
  const uploadProps = {
    onError: (e) => {
      message.error('Hubo un error cargando el archivo, intenta de nuevo.');
    },
    onSuccess: (e) => {
      message.success('Archivo cargado exitosamente')
      setResolucionPensionDoc(true)
    },
    accept: ".jpg, .pdf, .png",
    method: "POST",
    action: `${process.env.REACT_APP_DOCUMENTS_UPLOAD}investors/${userId}`,
    headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_DOCUMENTS }
  };
  return (
    <>
      <ul className={styles.container}>
        <li className={styles.select}>
          <label htmlFor='ocupacion'>Ocupación</label>
          <select name="ocupacion" onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'professionalData')} defaultValue={ocupacion} id='ocupacion'>
            {
              ocupaciones.map(({ key, id, value, name, label }) => (
                <option
                  key={key}
                  value={value}
                  name={name}
                  id={id}
                >{label}</option>
              ))
            }
          </select>
        </li>
        {
          ocupacion === 'empleado' && (
            <li className={styles.item}>
              <label htmlFor='lugarTrabajo'>Empleador</label>
              <input 
                type="text" 
                className={styles.custom_input}
                name='empleador'
                value={empleador ?? empresa}
                id='empleador'
                onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'professionalData')}
              />
            </li>
          )
        }
        <li className={styles.item}>
          <label htmlFor='profesion'>Profesión</label>
          <input 
            type="text" 
            className={styles.custom_input}
            name='profesion'
            value={profesion}
            id='profesion'
            onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'professionalData')}
          />
        </li>
        <li className={styles.select}>
          <label htmlFor='nivel_educacion'>Nivel de estudios</label>
          <select name="nivel_educacion" onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'professionalData')} defaultValue={nivelEducativo} id='ocupacion'>
            {
              nivelEstudios.map(({ key, id, value, name }) => (
                <option
                  key={key}
                  value={value}
                  name={name}
                  id={id}
                >{value}</option>
              ))
            }
          </select>
        </li>
      </ul>
      {
        ocupacion === "pensionado" && (
          <div className={styles.resolucion}>
            <p>Sube tu resolución de pensión</p>
            <Upload 
              {...uploadProps} 
              data={{ field: "documento_resolucion_pension" }} 
              showUploadList={false}
              disabled={resolucionPensionDoc}
            >
              <Button
                style={{ fontSize: "13px" }}
              >
                <Icon type='upload' /> { resolucionPensionDoc ? 'Archivo cargado' : 'Cargar archivo' }
              </Button>
            </Upload>
          </div>
        )
      }
    </>
  )
}

export default Dedico