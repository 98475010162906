export const dataUserForUpdate = {
  info: {
    personalData: false,
    professionalData: false,
    financialData: false,
    pepData: false,
  },
  docs: {
    identityDoc: false,
    rutDoc: false,
    bankAccount: false,
    rentaDoc: false,
    jobDoc: false,
    originFundsDoc: false,
  },
};

export function getNumberOfDays(createdDate) {
  const date1 = new Date(createdDate);
  const date2 = new Date();

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;
  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();
  // Calculating the no. of days between two dates
  return Math.round(diffInTime / oneDay);
}

export function isCompleteUpdateInfoUser(infoValidate) { //@params: info or docs string
  const { info, docs } = dataUserForUpdate;
  const values = Object.values(infoValidate === 'info' ? info : docs);
  return values.every((value) => value === true);
}

export function validateUserUpdateData(userData, userStatus) {
  if(userData.type_person === 'juridica') return false; //TODO: temporal
  const timeElapsed = getNumberOfDays(userData.modified);
  return (timeElapsed >= 365 && userStatus.id === '3') || isCompleteUpdateInfoUser('info');
}

export function updateDataItem(label, isDone, userUpdateState) {
  if(userUpdateState === false) return; 
  dataUserForUpdate.info = {
    ...dataUserForUpdate.info,
    [label]: isDone,
  };
}

export function validateUserUpdateDocs(userData, userInvestments) {
  const timeElapsed = getNumberOfDays(userData.modified);
  return (timeElapsed >= 365 && userInvestments.length === 0);
}