import React, { useEffect } from 'react'
import styles from "./NewProfile.module.scss";
import StepsNewProfile from './StepsNewProfile';
import { initialValuesProfile, questionsNewProfile, returnDataToSave, stepsNewProfile } from '../../../utils/constants';
import { useState } from 'react';
import StartForms from './StartForms';
import { createContext } from 'react';
import { useDispatch } from 'react-redux';
import { saveUserProfile } from '../../auth/Auth.duck';
import { updateDataItem } from '../../../utils/updateDataUser';
import { message } from 'antd';
import trackEvent from '../../../utils/analytics';

export const ProfileContext = createContext({})

const NewProfile = ({ props }) => {
    const dispatch = useDispatch()
    const userId = props.auth.user.User.id;
    const [valuesProfile, setValuesProfile] = useState(initialValuesProfile)
    const { first_name, last_name, last_name2, numero_documento, fecha_expedicion, fecha_nacimiento, ciudad_nacimiento, residencia, empresa, direccion, ciudad, pais, estado_civil, declara_renta, ingresos_mensuales, egresos_mensuales, activos, pasivos, razon_recursos, origen_fondos, ocupacion, profesion, nivel_educacion, empleador,  persona_pep, recursos_publicos, familia_publico, nombre_publico, parentesco_publico, pais_recursos, declaracion_origen
    } = props.auth.user.User
    const [stepVisible, setStepVisible] = useState(1)
    const [resolucionPensionDoc, setResolucionPensionDoc] =  useState(false)
    useEffect(() => {
        setValuesProfile({
            personalData: {
                first_name, 
                last_name, 
                last_name2, 
                numero_documento, 
                fecha_expedicion, 
                fecha_nacimiento, 
                ciudad_nacimiento, 
                residencia, 
                direccion, 
                ciudad, 
                pais, 
                estado_civil
            },
            financialData: {
                declara_renta, 
                ingresos_mensuales,
                egresos_mensuales,
                activos, 
                pasivos, 
                razon_recursos, 
                origen_fondos,
                pais_recursos, 
                declaracion_origen
            },
            professionalData: {
                ocupacion, 
                profesion, 
                nivel_educacion, 
                empleador,
                empresa
            },
            pepData: {
                persona_pep, 
                recursos_publicos, 
                familia_publico, 
                nombre_publico, 
                parentesco_publico
            }
        })
    }, [])
    useEffect(() => {
        if(stepVisible !== 4){
            const handleUnload = (e) => {
                e.preventDefault();
                e.returnValue = true;
            };
            window.addEventListener('beforeunload', handleUnload);
            const event = {
                category: "Flujo",
                action: "Abandona_perfil",
                label: `inversionista id: ${userId}`,
                value: `paso ${stepVisible}`,
            };
            trackEvent(event);
            return () => {
                window.removeEventListener('beforeunload', handleUnload);
            };
        }
    }, [stepVisible])
    const [startForms, setStartForms] = useState(false)
    const handleIncrease = (e) => {
        e.preventDefault()
        if(stepVisible < 4){
            setStepVisible(stepVisible + 1)
        }
        const _token = props.auth.jwt;
        const dataToSaveArray = returnDataToSave(valuesProfile, userId).filter((data) => data.id === stepVisible)
        const { labelData, dataToSave } = dataToSaveArray[0]
        const informacionFull = {
            personalData: 'personal_full',
            professionalData: 'profesional_full',
            financialData: 'financiero_full',
            pepData: 'pep_full'
        }
        const profileToUpdate = informacionFull[labelData]
        dataToSave[profileToUpdate] = true
        if(stepVisible === 4){
            window.location = '/dashboard/tablero'
        }
        if(stepVisible === 3) { 
            const { declaracion_origen } = dataToSave
            if(declaracion_origen === false) {
                message.error('Perfil no se ha guardado, debes aceptar la declaración de origen de fondos')
                setStepVisible(3)
                return
            }
        }
        if(stepVisible === 2){
            if(valuesProfile.professionalData.ocupacion === 'pensionado' && resolucionPensionDoc === false) {
                message.error('Debes subir tu resolución de pensión')
                setStepVisible(2)
            }
        }
        dispatch(saveUserProfile(_token, dataToSave)).then((_data) => {
            if (_data.response.success) {
                message.success("Perfil ha sido actualizado");
                updateDataItem(labelData, true, props.auth.userUpdateData);
            } else {
                message.success(_data.response.msg);
                updateDataItem(labelData, false, props.auth.userUpdateData);
            }
        });
    }
    const handleDecrease = (e) => {
        e.preventDefault()
        if(stepVisible > 1){
            setStepVisible(stepVisible - 1)
        }
        if(stepVisible === 1){
            setStartForms(false)
        }
    }

    const [extractedInfo, setExtractedInfo] = useState({})
    
    return (
        <ProfileContext.Provider value={{ extractedInfo, valuesProfile, setValuesProfile, props, setResolucionPensionDoc, resolucionPensionDoc }}>  
            {
                startForms === false && (
                    <StartForms 
                        setStartForms={setStartForms} 
                        extractedInfo={extractedInfo} 
                        setExtractedInfo={setExtractedInfo}
                    />
                )
            }
            {
                startForms === true && (
                    <section className={`${styles.container} profileContent`}>
                        <form className={styles.form}>
                            <div className={styles.steps_container}>
                                <div className={styles.line}></div>
                                {
                                    stepsNewProfile.map(({ step, stepText }) => (
                                        <StepsNewProfile number={step} text={stepText} key={step} stepVisible={stepVisible} setStepVisible={setStepVisible}/>
                                    ))
                                }
                            </div>
                            {
                                questionsNewProfile.map(({ component, id }) => {
                                    if(id === stepVisible){
                                        return component
                                    }
                                })
                            }
                            <div className={styles.buttons}>
                                <button className={styles.btn_atras} onClick={(e) => handleDecrease(e)}>Atrás</button>
                                <button className={styles.btn_adelante} onClick={(e) => handleIncrease(e)}>Guardar</button>
                            </div>
                        </form>
                    </section>
                )
            }
        </ProfileContext.Provider>
    )
}

export default NewProfile