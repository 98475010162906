import React, {useEffect, useState} from 'react'
import Topbar from '../common/topbar/Topbar'
import WorkingImage from "../../graphics/workingImage.png"
import styles from './AvancesFactoring.module.scss'
import axios from 'axios'

export default function AvancesFactoring (props) {
  const [reservationReportUrl, setReservationReportUrl] = useState(null)
  const inmueble_id = props.match.params.id;
  useEffect(async() => {
    await axios({
        method: "GET",
        url: `${process.env.REACT_APP_DATA_URL}/inmuebles/${inmueble_id}`,
        headers: {
          "Content-Type": "application/json",
        },
        json: true,
    }).then((response) => response.status === 200 && setReservationReportUrl(response.data.data.reservation_report_url))
}, [])
  return (
    <div id="homeContainer" className="ib-container container_avances">
        <header className="sticky"><Topbar selected={"inicio"} /> </header>
        <div className={styles.content}>
          {/* informe wellcome */}
          {
            reservationReportUrl !== null && reservationReportUrl.length ? (
                <div className={styles.double_col}>
                    <h2 className={styles.step}>Reservas Wellcome</h2>
                    <hr className={styles.separator_col} />
                    <div className={styles.iframe_container}>
                        <iframe src={reservationReportUrl} frameBorder="0" allowfullscreen></iframe>
                    </div>
                </div>
            ) : (<>
            <img src={WorkingImage} alt="working image" className={styles.image}/><h1 className={styles.text}>Estamos trabajando para incluir los avances de tú inversión en la plataforma, mientras tanto, recibirás las notificaciones de tú inversión quincenalmente mediante <span className={styles.wpp}>WhatsApp</span> a través del canal de notificaciones</h1>
            </>
            )
          }
        </div>
    </div>
  )
}