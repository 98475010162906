//import { combineReducers } from 'redux'
//import { connectRouter } from 'connected-react-router'
//
//import auth from "@auth/Auth.duck.js";
//import dashboard from "@dashboard/dashboard.duck";
//
//const createRootReducer = (history) => combineReducers({
//  router: connectRouter(history),
//  auth: auth,
//  dashboard: dashboard
//})
//export default createRootReducer


import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from "@auth/Auth.duck.js";
import dashboard from "@dashboard/dashboard.duck";
import logReducer from './logReducer'; // Importar el reducer de logs

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: auth,
  dashboard: dashboard,
  logs: logReducer // Agregar el reducer de logs
})
export default createRootReducer