import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

import logo from "@graphics/footer/weseed_fit.svg";
import camara from "@graphics/footer/camara.webp";
import epayco from "@graphics/footer/epayco.webp";
import fintech from "@graphics/footer/fintech_white.webp";
import proptech from "@graphics/footer/proptech_white.webp";
import superintendencia from "@graphics/footer/superintendencia.webp";
import LQN_logo from "@graphics/footer/lqn_white.webp";

import fb from "@graphics/logos/fb_new.webp";
import twitter from "@graphics/logos/twitter_new.webp";
import instagram from "@graphics/logos/instagram_new.webp";
import youtube from "@graphics/logos/youtube_new.webp";

import {Button, Icon, Modal } from "antd";
import aviso from "../../auth/legal/avisoPrivacidad";
import tratamiento from "../../auth/legal/tratamientoDatos";
import { tyc_url } from "../../auth/legal/terminosVersion";
import { BRAND_UP, BRAND_LOW, BRAND_DOMAIN, BRAND_DOMAIN_NAME } from "@utils/constants";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      privacy_modal: false,
      terminos_modal: false,
      tratamiento_modal: false,
    };
  }

  openPrivacy() {
    this.setState({ privacy_modal: true });
  }

  handlePrivacyClose() {
    this.setState({ privacy_modal: false });
  }

  openTerminos() {
    this.setState({ terminos_modal: true });
  }

  handleTerminosClose() {
    this.setState({ terminos_modal: false });
  }

  openTratamiento() {
    this.setState({ tratamiento_modal: true });
  }
 
  handleTratamientoClose() {
    this.setState({ tratamiento_modal: false });
  }

  
  render() {
    return (
      <footer className="footer_container">
        <Modal
          title={
            <h2 style={{ fontWeight: "600", color: "#5F5F5F" }}>
              Aviso de privacidad
            </h2>
          }
          visible={this.state.privacy_modal}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={() => this.handlePrivacyClose()}
            >
              Cerrar
            </Button>,
          ]}
          onCancel={() => this.handlePrivacyClose()}
        >
          <div class="textarea"> {aviso} </div>
        </Modal>

        <Modal
          title={
            <h2 style={{ fontWeight: "600", color: "#5F5F5F" }}>
              Términos y Condiciones
            </h2>
          }
          visible={this.state.terminos_modal}
          width={"800px"}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={() => this.handleTerminosClose()}
            >
              Cerrar
            </Button>,
          ]}
          onCancel={() => this.handleTerminosClose()}
        >
          <iframe
            src={tyc_url}
            width="100%"
            height="480"
            allow="autoplay"
          ></iframe>
        </Modal>

        
        <Modal
          title={
            <h2 style={{ fontWeight: "600", color: "#5F5F5F" }}>
             Tratamiento de datos
            </h2>
          }
          visible={this.state.tratamiento_modal}
          footer={[
            <Button
              key='submit'
              type='primary'
              onClick={() => this.handleTratamientoClose()}
            >
              Aceptar
            </Button>,
          ]}
          onCancel={() => this.handleTratamientoClose()}
        >
          <div class='textarea'> {tratamiento} </div>
        </Modal>

        <div className="footer__about">
          <div className="footer__about--column">
            <Link to="/nosotros">
              <h1 className="footer_item_title" style={{ textAlign: "center" }}>
                Nosotros
              </h1>
            </Link>
            <div className="footer_item">
              {/*<Link to="/nosotros#acerca"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <p className="footer_item_content">Acerca de { BRAND_DOMAIN }</p>{" "}
              </Link>
              <Link to="/nosotros#mision"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <p className="footer_item_content">Misión/Visión y Valores</p>{" "}
              </Link>
              <Link to="/nosotros#team">
                <p className="footer_item_content">Conoce al equipo</p>{" "}
              </Link>*/}
              <span onClick={() => this.openTerminos()}>
                <p className="footer_item_content">Términos y condiciones</p>{" "}
              </span>
              <span onClick={() => this.openPrivacy()}>
                <p className="footer_item_content">Aviso de privacidad</p>{" "}
              </span>
              <span onClick={() => this.openTratamiento()}>
                <p className="footer_item_content">Tratamiento de datos</p>{" "}
              </span>
            </div>
          </div>

          {/*
          <Col  span={6} className="footer_col">
            <h1 className="footer_item_title" style={{textAlign: 'center'}}>DESARROLLADOR</h1>
            <div className="footer_item">
              <p  className="footer_item_content">Finánciate con { BRAND_LOW }</p>
              <a href="https://docs.google.com/forms/d/1R4iDk8mOWBvbqqP5mzQHBbHH6Puf_X97ZydEHbncW0Y/viewform?edit_requested=true">
              <p  className="footer_item_content">Solicitud inicial</p>
              </a>
              <Link to="/faq#faq"><p  className="footer_item_content">FAQ</p></Link>
           </div>
          </Col>
          */}

          <div className="footer__about--column">
            <h1 className="footer_item_title" style={{ textAlign: "center" }}>
              Inversionista
            </h1>
            <div className="footer_item">
              {/*<Link to="/conocer/invertir">
                <p className="footer_item_content">¿Cómo invertir?</p>
              </Link>*/}
              <Link to="/inmuebles#inmuebles">
                <p className="footer_item_content">
                  Oportunidades de inversión
                </p>
              </Link>
              {/*<Link to="/simulador#simulador"><p  className="footer_item_content">Simulador de inversión</p></Link>*/}
              <Link to="/signup">
                <p className="footer_item_content">Registrarme</p>
              </Link>
              <Link to={{ pathname: "https://ayuda.soyweseed.com" }} target="_blank">
                <p className="footer_item_content">Ayuda</p>
              </Link>
            </div>
          </div>

          <div className="footer__about--column">
            <h1 className="footer_item_title">Contacto</h1>
            <div className="footer_item">
              <p className="footer_item_content">
                <Icon type="pushpin" className="f_icon" theme="filled" /> CL 75A 66 50 OF 306 OFIESPACIOS
              </p>

              <p className="footer_item_content">
                <Icon type="mail" className="f_icon" theme="filled" />{" "}
                hola@{ BRAND_DOMAIN }
              </p>

              <p className="footer_item_content">
                <Icon type="phone" className="f_icon" theme="filled" /> (+57)
                3196251522
              </p>
            </div>
          </div>
        </div>

        <div className="footer__info">
            
        {this.props.showPartners && <>
          <div className="title-container">
            <p className="partners__title">Nuestros aliados</p>
          </div>
          <div className="partners__items">
            <div
              className="partner"
              style={{ backgroundImage: `url(${epayco})` }}
            ></div>
            <div
              className="partner"
              style={{ backgroundImage: `url(${proptech})` }}
            ></div>
            <div
              className="partner"
              style={{ backgroundImage: `url(${superintendencia})` }}
            ></div>
            <div
              className="partner"
              style={{ backgroundImage: `url(${fintech})` }}
            ></div>
            <div
              className="partner"
              style={{ backgroundImage: `url(${camara})` }}
            ></div>
            <div
              className="partner"
              style={{ backgroundImage: `url(${LQN_logo})` }}
            ></div>
          </div>
        </>}
          <div className="container__logos">
            <div className="footer__logo">
              <img src={logo} className="footer__logo--img" alt={`${ BRAND_LOW } logo`} />
              <div className="company_info">{ BRAND_UP } NIT 901403018-0</div>
            </div>

            <div className="footer__redes">
              <div></div>
              <a
                href={`https://www.facebook.com/${ BRAND_DOMAIN_NAME }`}
                className="redes"
                rel="noreferrer"
                target="_blank"
                >
                <img src={fb} alt={`${ BRAND_LOW } Facebook`} />
              </a>
              <a
                href={`https://www.instagram.com/${ BRAND_DOMAIN_NAME }`}
                className="redes"
                rel="noreferrer"
                target="_blank"
                >
                <img src={instagram} alt={`${ BRAND_LOW } instagram`} />
              </a>
              <a
                href={`https://www.twitter.com/${ BRAND_DOMAIN_NAME }`}
                className="redes"
                rel="noreferrer"
                target="_blank"
                >
                <img src={twitter} alt={`${BRAND_LOW } twitter`} />
              </a>
              <a
                href="https://www.youtube.com/channel/UCuRlM5iEWWza3GLv0Iur3tg?view_as=subscriber"
                className="redes"
                rel="noreferrer"
                target="_blank"
                >
                <img src={youtube} alt={`${ BRAND_LOW } youtube`} />
              </a>
            </div>
          </div>
        </div>

        <div className="superintendencia">
          <a
            href="https://www.sic.gov.co/"
            target="blank"
            className="super_link"
            >
            Superintendencia de Industria y Comercio
          </a>
        </div>
      </footer>
    );
  }
}

export default Footer;
