let _store;

export default function undo({ getState, dispatch }) {
  return next => action => {
    const returnValue = next(action);
    const nextState = getState();
    _store = nextState;
    const actionType = String(action.type);
    switch (actionType) {
      case "ADD_TO":
      case "REMOVE":          
      case "MOVE_TO":
      case "RENAME":
      case "ADD_ELEM_CLASS":
      case "RM_ELEM_CLASS":
      case "SET_PROP":
      case "ADD_CSS_CLASS":
      case "RM_CSS_CLASS":
      case "RM_CSS_ID":
      case "ADD_TEXT":
      case "RM_TEXT":
        stepAction(actionType);
        break;
      case "SELECT":
        stepSelection();
        break;
      case "INITIAL_STATE":
        stepInitial(actionType);
        break;
    }
    return returnValue;
  };
}

// TODO: Limit the size of each array to max 20

let _state = {
  past: [],
  present: {},
  future: [],
  list: [],
  selection: []
};

let _moment;

const stepSelection = () => {
  const { selection } = _state;  
  const previous = selection[selection.length - 1];  
  const _selected = _store.selection.selected;  
  if (previous !== _selected) {
    _state.selection.push(_selected);    
  }
};

const stepAction = actionType => {
  const { past, present, list } = _state;

  _moment = {
    structure: JSON.stringify(_store.structure),
    css: JSON.stringify(_store.css),
    text: JSON.stringify(_store.text)
  };

  _state.past = [...past, present];
  _state.present = _moment;
  _state.future = [];
  _state.list = [...list, actionType];
};

export const stepUndo = () => {
  const { past, present, future, list, selection } = _state;
  const previous = past[past.length - 1];
  if (previous) {    
    const newPast = past.slice(0, past.length - 1);
    const newList = list.slice(0, list.length - 1);

    // In case of being a ADD_TO or REMOVE Action, set the selection one step back
    switch(list[list.length - 1]) {      
      case "ADD_TO":                
        _state.selection = selection.slice(0, selection.length - 1);                     
        const _selectEvent = new CustomEvent("selectElement", { detail: _state.selection[_state.selection.length - 1] });
        document.dispatchEvent(_selectEvent);
        break;
      
      case "REMOVE": //TODO
        break;
    }

    _state.past = newPast;
    _state.present = previous;
    _state.future = [present, ...future];
    _state.list = newList;    

    const _undoEvent = new CustomEvent("undoEvent", { detail: _state.present });
    document.dispatchEvent(_undoEvent);
  }  
};

export const stepRedo = () => {
  const { past, present, future, list } = _state;
  const next = future[0];
  if (next) {
    const newFuture = future.slice(1);
    const newList = list.slice(1);

    _state.past = [...past, present];
    _state.present = next;
    _state.future = newFuture;
    _state.list = newList;

    const _undoEvent = new CustomEvent("undoEvent", { detail: _state.present });
    document.dispatchEvent(_undoEvent);
  }  
};

const stepInitial = () => {
  _moment = {
    structure: JSON.stringify(_store.structure),
    css: JSON.stringify(_store.css),
    text: JSON.stringify(_store.text)
  };

  _state.past = [];
  _state.present = _moment;
  _state.future = [];
  _state.list = [];
};
