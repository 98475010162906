import React, { useEffect, useState } from 'react'
import styles from './MisFinanzas.module.scss'
import { declaracionOrigenFondosText, handleChange } from '../../../utils/constants'
import { useContext } from 'react'
import { ProfileContext } from './NewProfile'
import { Africa, Asia, Europe, MiddleAmerica, MiddleEast, NorhtAmerica, Oceania, SouthAmerica } from './forms/lists/Countries'
import { Select, InputNumber, Modal, Button } from 'antd'
import './general.css'

const { Option, OptGroup } = Select;

const MisFinanzas = () => {
  const [openModal, setOpenModal] = useState(false)
  const { valuesProfile, setValuesProfile } = useContext(ProfileContext);
  const { financialData } = valuesProfile;
  const [statusOrigenFondos, setStatusOrigenFondos] = useState('Rechazado')
  const { activos, pasivos, ingresos_mensuales, egresos_mensuales, origen_fondos, pais_recursos, declaracion_origen } = valuesProfile.financialData
  useEffect(() => {
    declaracion_origen === true ? setStatusOrigenFondos('Aceptado') : setStatusOrigenFondos('Rechazado')
  }, [declaracion_origen])
    const handleChangeCustomInput = (value, type) => {
      const newValues = {
        ...valuesProfile,
        financialData: {
          ...financialData,
          [type]: value,
        }
      }
      setValuesProfile(newValues)
    }
    const handleDeclaracionOrigenFondos = (e) => {
      const { target: { innerText } } = e
      let valueToSend
      innerText === 'Si' ? valueToSend = true : valueToSend = false
      const newValues = {
        ...valuesProfile,
        financialData: {
          ...financialData,
          declaracion_origen: valueToSend,
        }
      }
      setValuesProfile(newValues)
      setOpenModal(false)
    }
    const statusDeclaracionTrue = (declaracion_origen === true) ? 'statusDeclaracionTrue' : '';
  return (
    <section className={styles.container}>
      <Modal
        title="Declaración origen de fondos"
        visible={openModal}
        footer={
          <div>
            <Button type='primary' onClick={(e) => handleDeclaracionOrigenFondos(e)}>Si</Button>
            <Button type='secondary' onClick={(e) => handleDeclaracionOrigenFondos(e)}>No</Button>
          </div>
        }
        width={"700px"}
      >
        {declaracionOrigenFondosText}
      </Modal>
      <p className={styles.question_title}>Ingresa el valor de: </p>
      <ul className={styles.finanzas}>
        <li className={styles.finanzas_item}>
          <label htmlFor='activos'>Activos</label>
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            max={1000000000000}
            min={0}
            onChange={(value) => handleChangeCustomInput(value, 'activos')}
            value={activos}
            style={{ width: '100%' }}
          />
        </li>
        <li className={styles.finanzas_item}>
          <label htmlFor='pasivos'>Pasivos</label>
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            max={1000000000000}
            min={0}
            onChange={(value) => handleChangeCustomInput(value, 'pasivos')}
            value={pasivos}
            style={{ width: '100%' }}
          />
        </li>
        <li className={styles.finanzas_item}>
          <label htmlFor='ingresos'>Ingresos</label>
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            max={1000000000000}
            min={0}
            onChange={(value) => handleChangeCustomInput(value, 'ingresos_mensuales')}
            value={ingresos_mensuales}
            style={{ width: '100%' }}
          />
        </li>
        <li className={styles.finanzas_item}>
          <label htmlFor='activos'>Egresos</label>
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            max={1000000000000}
            min={0}
            onChange={(value) => handleChangeCustomInput(value, 'egresos_mensuales')}
            value={egresos_mensuales}
            style={{ width: '100%' }}
          />
        </li>
      </ul>
      <ul className={styles.recursos}>
        <li>
          <p className={styles.question_title}>¿De dónde provienen los fondos que invertirás?</p>
          <input 
          type="text" 
          className={styles.custom_input} 
          placeholder='Ej: Mis recursos provienen de mi trabajo como ...'
          name='origen_fondos'
          value={origen_fondos}
          id='origen_fondos'
          onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'financialData')}
          />
        </li>
        <li>
          <p className={styles.question_title}>País de origen de los recursos:</p>
          <Select name="pais_recursos" id="pais_recursos" defaultValue={pais_recursos}>
            <OptGroup label='Suramerica'>
              {SouthAmerica.map((country) => (
                <Option 
                  key={country.name} 
                  value={country.name}
                  onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'financialData')}>
                  {country.name}
                </Option>
              ))}
              </OptGroup>
              <OptGroup label='Norteamérica'>
              {NorhtAmerica.map((country) => (
                <Option 
                  key={country.name} 
                  value={country.name} 
                  onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'financialData')}>
                  {country.name}
                </Option>
              ))}
              </OptGroup>
              <OptGroup label='Centroamérica y Caribe'>
              {MiddleAmerica.map((country) => (
                <Option 
                  key={country.name} 
                  value={country.name} 
                  onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'financialData')}>
                  {country.name}
                </Option>
              ))}
              </OptGroup>
              <OptGroup label='Europa'>
              {Europe.map((country) => (
                <Option 
                  key={country.name} 
                  value={country.name} 
                  onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'financialData')}>
                  {country.name}
                </Option>
              ))}
              </OptGroup>
              <OptGroup label='Asia'>
              {Asia.map((country) => (
                <Option 
                  key={country.name} 
                  value={country.name} 
                  onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'financialData')}>
                  {country.name}
                </Option>
              ))}
              </OptGroup>
              <OptGroup label='Oriente Medio'>
              {MiddleEast.map((country) => (
                <Option 
                  key={country.name} 
                  value={country.name} 
                  onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'financialData')}>
                  {country.name}
                </Option>
              ))}
              </OptGroup>
              <OptGroup label='África'>
              {Africa.map((country) => (
                <Option 
                  key={country.name} 
                  value={country.name} 
                  onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'financialData')}>
                  {country.name}
                </Option>
              ))}
              </OptGroup>
              <OptGroup label='Oceanía'>
              {Oceania.map((country) => (
                <Option 
                  key={country.name} 
                  value={country.name} 
                  onChange={(e) => handleChange(e, valuesProfile, setValuesProfile, 'financialData')}>
                  {country.name}
                </Option>
              ))}
              </OptGroup>
          </Select>
        </li>
      </ul>
      <div className={styles.declaracion}>
        <p className={styles.origen_fondos} onClick={() => setOpenModal(true)}>Declaración de origen de fondos</p>
        <div className={`${styles.estado_declaracion} ${statusDeclaracionTrue}`}>{statusOrigenFondos}</div>
      </div>
    </section>
  )
}
export default MisFinanzas