import React from "react";
import { Route, Switch } from "react-router";
import ReactGA from 'react-ga';
import loadable from "@loadable/component";
import NotFound from "../components/404/404";

import '@styles/App.scss';
import '@styles/antd.overrides.scss';
import TwoFaAuth from "../components/auth/twofa/TwoFaAuth";
import AvancesFactoring from "../components/avancesFactoring/AvancesFactoring";


const Home = loadable(() => import('@home'))

const Dashboard = loadable(() => import('@dashboard'))
const Contacto = loadable(() => import('@contacto'))
const Nosotros = loadable(() => import('@nosotros'))
const Terminos = loadable(() => import('@terminos'))
const TerminosModal = loadable(() => import('@terminos/TerminosModal'))
const Avances = loadable(() => import('@dashboard/avances/Avances'))
const InmuebleList = loadable(() => import('@inmuebleList'))

const Compra = loadable(() => import('@compra/Compra.js'))
const Auth = loadable(() => import('@auth'))
const InmuebleDetails = loadable(() => import('@inmuebleDetails'))
const Educacion = loadable(() => import('@educacion'))
const Faq = loadable(() => import('@faq'))
const Invertir = loadable(() => import('@conocer/Invertir.js'))
const Seleccion = loadable(() => import('@conocer/Seleccion.js'))
const Constructor = loadable(() => import('@constructor'))
const InmuebleDetailsCompra = loadable(() => import('@compra/InmuebleDetails/InmuebleDetailsCompra.js'))
const Cookiesalert = loadable(() => import('@common/cookies/index.js'))
const Video = loadable(() => import('@home/video'))

//const Pomelo = loadable(() => import('@inmuebleDetails'))

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  componentDidMount() {
    // Set window env variable
    switch (window.location.host) {
      case "imoblo.co":
      case "soyweseed.com":
        window.env = "production"
        break;
      default:
        window.env = "development"
    }
    ReactGA.initialize(process.env.REACT_APP_GA, {
      debug: (window.env !== "production"),
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <React.Fragment>
       <Cookiesalert />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/karems" component={Home} />
          <Route exact path="/inicio" component={Home} />
          <Route exact path="/nosotros" component={Nosotros} />
          <Route exact path="/constructor" component={Constructor} />
          <Route exact path="/contacto" component={Contacto} />
          <Route exact path="/educacion" component={Educacion} />
          <Route exact path="/terminos" component={Terminos} />
          <Route exact path="/conocer" component={Invertir} />
          <Route exact path="/conocer/invertir" component={Invertir} />
          <Route exact path="/conocer/seleccion" component={Seleccion} />
          <Route exact path="/compra" component={Compra} />
          <Route exact path="/compra/proyectos/:project_id" component={InmuebleDetailsCompra} />
          <Route exact path="/f23c9de9a31dbf557c2ebad4859d198d" component={Video} />
          <Route exact path="/twofacode/:twofa" component={TwoFaAuth} />

          <Route exact path="/inmuebles" component={InmuebleList} />
          <Route exact path="/inmuebles/:id" component={InmuebleDetails} />
          <Route exact path="/vip/:id" component={InmuebleDetails} />
          <Route exact path="/proyectos/:id" component={InmuebleDetails} />
          {/* <Route exact path="/" component={Home} /> */}

          <Route exact path="/login" component={Auth} />
          <Route exact path="/logout" component={Auth} />
          <Route exact path="/signup" component={Auth} />
          <Route exact path="/confirm" component={Auth} />
          <Route exact path="/forgot" component={Auth} />
          <Route exact path="/invitation/:token" component={Auth} />
          <Route exact path="/reset/:token" component={Auth} />
          <Route exact path="/verifyEmail/:token" component={Auth} />

          <Route exact path="/avances/:id" component={Avances} />
          <Route exact path="/avances/factoring/:id" component={AvancesFactoring} />

          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/dashboard/inversiones" component={Dashboard} />
          <Route exact path="/dashboard/inversiones/:id" component={Dashboard} />
          <Route exact path="/dashboard/cuenta" component={Dashboard} />

          <Route exact path="/dashboard/tablero" component={Dashboard} />
          <Route exact path="/dashboard/avances/:id" component={Avances} />
          <Route exact path="/dashboard/mercado" component={Dashboard} />
          <Route exact path="/dashboard/mercado/:inmueble_id" component={Dashboard} />
          <Route exact path="/dashboard/mercado/:inmueble_id/confirmacion/:investment_id" component={Dashboard} />
          <Route exact path='/dashboard/confirmacion' component={Dashboard} />
          <Route exact path="/dashboard/documentos" component={Dashboard} />
          <Route exact path="/dashboard/soporte" component={Dashboard} />
          <Route exact path="/dashboard/perfil" component={Dashboard} />
          <Route exact path="/pse/response/:id" component={Dashboard} />
          <Route exact path="/terms" component={TerminosModal} />

          <Route render={() => <NotFound />} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
