//Versión actual de los términos y condiciones globales de uso de la plataforma
export const current_tyc_version = 4;

//Url del documento de tyc
export const tyc_url = "https://weseed-cms-public.s3.amazonaws.com/web/docs/terminos-y-condiciones/2024-14-06_weseed_v4.pdf"

// export const tyc_url_v3 = "https://drive.google.com/file/d/1M_cWIPOF7zsD1cUs7KIPFAMTwcR_rWlR/preview";

//Url del documento de tyc para fast track
export const tyc_url_fast = "https://drive.google.com/file/d/1RoooY800z5Iq9iDaOfr33kBnjh9DhgXr/preview";
