import React from 'react'
import styles from "./StepsNewProfile.module.scss";
import './general.css'

const StepsNewProfile = ({ number, text, stepVisible, setStepVisible }) => {
  const color = (number === stepVisible) ? 'isActive' : '';
  return (
    <div className={styles.step_container} onClick={() => setStepVisible(number)}>
        <div className={`${styles.step} ${color}`}>
            { number }
        </div>
        <p>{ text }</p>
    </div>
  )
}

export default StepsNewProfile