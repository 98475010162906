import axios from "axios";

const DASH_LOADING = "DASH_LOADING";
const DASH_SERVER_ERROR = "DASH_SERVER_ERROR";
const CLEAR_DASHBOARD = "CLEAR_DASHBOARD";
const CLEAR_ACCOUNT = "CLEAR_ACCOUNT";
const DASH_DONE = "DASH_DONE";
const PROJECTS = "PROJECTS";
const INMUEBLES = "INMUEBLES";
const INMUEBLE = "INMUEBLE";

const initialState = {
  server_error: false,
  loading: false,
  inmuebles: [],
  projects: []
};

// REDUCERS --- : Handle the result of the actions

export default (state = initialState, action) => {
  switch (action.type) {
    case DASH_LOADING:
      return {
        ...state,
        server_error: false,
        loading: true
      };
    case DASH_DONE:
      return {
        ...state,
        server_error: false,
        loading: false
      };
    case PROJECTS:
      return {
        ...state,
        projects: action.projects,
        server_error: false,
        loading: false
      };
    case INMUEBLES:
      return {
        ...state,
        inmuebles: action.inmuebles,
        server_error: false,
        loading: false
      };
    case INMUEBLE:
      const _inmuebles = state.inmuebles;
      _inmuebles.push(action.inmueble);
      return {
        ...state,
        inmuebles: _inmuebles,
        server_error: false,
        loading: false
      };
    case CLEAR_DASHBOARD:
      return initialState;
    case CLEAR_ACCOUNT:
      return {
        ...state,
        account: null
      };
    case DASH_SERVER_ERROR:
      return {
        ...state,
        loading: false,
        server_error: true
      };
    default:
      return state;
  }
};

// ACTIONS --- : Perform a change, call or (as its name implies) an action or logic

function actionLoading() {
  return { type: DASH_LOADING };
}

export function clearAccount() {
  return { type: CLEAR_ACCOUNT };
}

export function clearDashboard() {
  return { type: CLEAR_DASHBOARD };
}
function serverError() {
  return { type: DASH_SERVER_ERROR, response: { success: false } };
}
// NOTE:
/*
 *   JWT has to be passed from the component to the actions
 *
 */

export function getProjects(_token) {
  return dispatch => {
    dispatch(actionLoading());
    const _headers = {
      "Content-Type": "application/json"
    }
    if (_token) {
      _headers['Authorization'] = `bearer ${_token}`
    }
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_DATA_URL}/projects`,
      headers: _headers,
      json: true
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data.success) {
            return dispatch({ type: PROJECTS, projects: response.data.data });
          } else {
            return dispatch({ type: DASH_DONE });
          }
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
}

export function getInmuebles(_token) {
  return dispatch => {
    dispatch(actionLoading());
    const _headers = {
      "Content-Type": "application/json"
    }
    if (_token) {
      _headers['Authorization'] = `bearer ${_token}`
    }
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_DATA_URL}/inmuebles`,
      headers: _headers,
      json: true
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data.success) {
            return dispatch({ type: INMUEBLES, inmuebles: response.data.data });
          } else {
            return dispatch({ type: DASH_DONE });
          }
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
}

export function getInmueble(_token, _inmueble_id) {
  return dispatch => {
    dispatch(actionLoading());
    const _headers = {
      "Content-Type": "application/json"
    }
    if (_token) {
      _headers['Authorization'] = `bearer ${_token}`
    }
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_DATA_URL}/inmuebles/${_inmueble_id}`,
      headers: _headers,
      json: true
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data.success) {
            return dispatch({ type: INMUEBLE, inmueble: response.data.data });
          } else {
            return dispatch({ type: DASH_DONE });
          }
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
}

export function saveInvestment(_token, _data) {
  return dispatch => {
    dispatch(actionLoading());
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/investments/create`,
      headers: {
        "Accept": "*/*",
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${_token}`
      },
      data: _data
    })
      .then(response => {
        console.log('response--', response);
        if (response.status === 200) {
          if (response.data.success) {
            return dispatch({ type: DASH_DONE, response: response.data });
          } else {
            return dispatch({ type: DASH_DONE, response: response.data });
          }
        } else {
          // Server responded with an Error, 500, 404, etc
          return dispatch(serverError());
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
}


