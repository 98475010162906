// src/store/logReducer.js
const initialState = {
    logs: []
  };
  
  const logReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'CREATE_LOG':
        return { ...state, logs: [...state.logs, action.payload] };
      default:
        return state;
    }
  };
  
  export default logReducer;