import React from "react";
import styles from "./loader.module.scss";

export default function Loader() {
	return (
		<div className={styles.loading}>
			<div className={styles.load}>
				<div className={styles.loader}></div>
			</div>
		</div>
	);
}
